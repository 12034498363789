<!--
 * @Author: ttheitao
 * @Description: 自定义excel模板
 * @Date: 2022-07-18 10:52:02
 * @LastEditors: ttheitao
 * @LastEditTime: 2022-07-19 11:37:13
-->
<template>
  <div class="custom-excel-container">
    <div class="top-actions">
      <el-dropdown @command="handleCommand">
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd(3)">新增模板</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="list">列表模板</el-dropdown-item>
          <el-dropdown-item command="detail">详情模板（默认）</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-table :data="dataList" style="width: 100%" v-loading="loading">
      <el-table-column prop="name" label="模板名称"> </el-table-column>
      <el-table-column prop="type" label="模板类型">
        <template slot-scope="scope">
          {{ scope.row.type === 2 ? "列表模板" : "详情模板" }}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="是否为默认模板">
        <template slot-scope="scope">
          <i
            v-if="scope.row.is_default"
            class="el-icon-success"
            style="color: #f56c6c; font-size: 16px"
          ></i>
          <i v-else class="el-icon-error" style="color: #909399; font-size: 16px"></i>
        </template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">
          {{ scope.row.config ? scope.row.config.remark : "" }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="300">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="!scope.row.is_default"
            @click="handleSetDefault(scope.row)"
            >设为默认模板</el-button
          >
          <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>

          <popconfirm title="确定删除吗？" @confirm="handleDel(scope.row)">
            <el-button
              slot="reference"
              type="text"
              size="small"
              style="color: #f56c6c; margin-left: 10px"
            >
              删除
            </el-button>
          </popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      custom-class="form-bind-excel-dialog"
      :title="modalTitle"
      :visible.sync="showExcelModal"
      fullscreen
    >
      <Entry
        v-if="showExcelModal"
        ref="entry"
        loadType="create"
        type="bind"
        :excelUuid="excelUuid"
        :formUuid="this.tableInfo.module_uuid"
        elementId="formExcelBind"
        :excelType="type"
        @sss-uploaded="sssUploaded"
      ></Entry>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExcelModal = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { Popconfirm } from "element-ui";
import Entry from "@/custom-component/common/Entry.vue";
import { dataInterface } from "@/apis/data";

export default {
  components: {
    Popconfirm,
    Entry,
  },
  props: {
    tableInfo: {
      type: Object,
    },
  },
  data() {
    return {
      dataList: [],
      excelUuid: "",
      showExcelModal: false,
      loading: true,
      modalTitle: "新增模板",
      type: 1,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      dataInterface({
        __method_name__: "spreadsheetList",
        type: [2, 3],
        size: 1000,
        owner_uuid: this.tableInfo.module_uuid,
      })
        .then((res) => {
          if (res.status === 200) {
            this.dataList = res.data.data.data;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleAdd(type) {
      this.modalTitle = "新增模板";
      this.excelUuid = "";
      this.type = type;
      this.showExcelModal = true;
    },
    handleEdit(row) {
      this.modalTitle = "编辑模板";
      this.excelUuid = row.uuid;
      this.type = row.type;
      this.showExcelModal = true;
    },
    handleDel(row) {
      this.loading = true;
      dataInterface({
        __method_name__: "spreadsheetDelete",
        id: row.uuid,
      })
        .then((res) => {
          if (res.status === 200) {
            this.getData();
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSetDefault(row) {
      this.loading = true;
      dataInterface({
        __method_name__: "updateSpreadsheet",
        id: row.uuid,
        is_default: 1,
      })
        .then((res) => {
          if (res.status === 200) {
            this.getData();
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    submit() {
      this.$loading();
      this.$refs.entry.triggerUpload();
    },
    sssUploaded() {
      this.$loading().close();
      this.showExcelModal = false;
      this.getData();
    },
    handleCommand(command) {
      let type = 3;
      if (command === "list") {
        type = 2;
      }

      this.handleAdd(type);
    },
  },
};
</script>

<style lang="less" scoped>
.custom-excel-container {
  height: 100%;
  .top-actions {
    padding: 10px;
    text-align: right;
    border-bottom: 1px solid #ebeef5;
  }
  .list {
    display: flex;
    justify-self: start;
    .item {
      width: 200px;
      height: 250px;
      padding: 10px;
      border: 1px solid #e6e6e6;
      border-radius: 5px;
      margin-left: 20px;
      margin-top: 20px;
      text-align: center;

      .thumb {
        font-size: 30px;
        color: #409eff;
        margin-top: 30px;
      }
      .name {
        padding: 20px;
      }

      .actions {
        text-align: center;
      }
    }
  }
}
</style>

<style lang="less">
.form-bind-excel-dialog {
  display: flex;
  flex-direction: column;

  .el-dialog__body {
    flex: 1;
  }
}
</style>
