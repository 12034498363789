<!--
 * @Author: your name
 * @Date: 2021-12-09 11:36:08
 * @LastEditTime: 2021-12-23 16:11:53
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\views\qualityMeter\components\customConfig.vue
-->

<template>
  <div v-loading="showList" class="flow">
    <FlowDesign v-if="!switchVisible && flowData" :flow-data="flowData"></FlowDesign>
    <div v-if="switchVisible" class="switch-show">
      <div>
        <img src="@/assets/images/flow.jpg" alt="">
        <p v-if="isForm">流程用于实现数据填写后的审批需求</p>
        <p v-if="isForm">如无需启用流程，可跳过此步骤</p>
        <p v-if="!isForm">暂未创建表单，请新增表单后使用流程！</p>
        <el-button v-else type="primary" class="button-class" @click="openFlow">开启流程</el-button>
      </div>
    </div>
    <div v-if="!switchVisible && flowData" class="close-button">
      <el-button type="primary" class="button-class" @click="closeFlow">关闭流程</el-button>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import FlowDesign from "@/custom-component/form/formParser/flowEngine/components/FlowDesign";
import { dataInterface } from "@/apis/data/index";
import { flowEngine } from "@/apis/data/workflow.js";
import { getFromValue,editFromValue } from "@/apis/data/customForm";
export default {
  components: { FlowDesign,'el-button':Button },
  data() {
    return {
      showTable:false,
      flowData: null,
      showList: false,
      switchVisible: true,
      queryObj: {},
      form_uuid: null,
      formdesignData:null,
      isForm: false
    };
  },
  props: {
    tableInfo: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {
    /* let params = {
      module_name: this.queryObj.name,
      module_uuid: this.queryObj.form_uuid,
    }
    this.flowDesign(params) */
    this.getInfo()
  },
  methods: {
    getFlowDesign (param) {
      flowEngine.designShow(param).then((res) => {
        this.flowData = res.data.data
        this.switchVisible = false
        this.showList = false
      })
    },
    getInfo() {
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object626118627602e",
        transcode: 0,
        data_id: this.tableInfo.id,
      };
      this.showList = true
      dataInterface(params).then( async (res) => {
        this.detailInfo = res.data.data;
        let formUuid = ''
        if(this.detailInfo.module_uuid) {
          formUuid = this.detailInfo.module_uuid
        }
        // 没有拿到module_uuid  提示去新增表单  不进行下面的请求
        if(!formUuid) {
          this.showList = false
          return
        }else{
          this.isForm = true
        }
        getFromValue({},formUuid).then((res) => {
          this.formdesignData = res.data.data
          if(res.data.data.need_flow) {
            this.switchVisible = true
            this.getFlowDesign({ module: this.detailInfo.module_uuid })
          }else{
            this.showList = false
          }
        })
      });
    },
    // 打开流程
    openFlow() {
      this.showList = true
      editFromValue({...this.formdesignData,need_flow:1},this.detailInfo.module_uuid).then((res) => {
        if(res.data.code === 200) {
          this.switchVisible = true
          this.getFlowDesign({ module: this.detailInfo.module_uuid })
        }
      })
    },
    // 关闭流程
    closeFlow() {
      this.showList = true
      editFromValue({...this.formdesignData,need_flow:0},this.detailInfo.module_uuid).then((res) => {
        this.showList = false
        if(res.data.code === 200) {
          this.switchVisible = true
          this.flowData = null
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.flow {
  width: 100%;
  height: 100%;
  position: relative;
  .close-button {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
  .switch-show {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    p {
      color: #999;
      font-size: 16px;
      text-align: center;
      margin: 5px 0;
    }
    .button-class {
      margin-top: 10px;
    }
  }
}
</style>
