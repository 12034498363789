<!--
 * @Author: your name
 * @Date: 2021-12-09 10:55:19
 * @LastEditTime: 2022-07-18 10:52:52
 * @LastEditors: ttheitao
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \dataview-viewer-test\src\views\qualityMeter\index.vue
-->

<template>
  <div class="quality">
    <div class="quality-tree">
      <p class="title">
        功能表单管理
        <span style="float: right;cursor: pointer" @click="addTreeMenu">
          <i class="iconfont iconbim_jia"></i>
        </span>
      </p>
      <form-menu
        ref="formMenu"
        v-if="treeData && treeData.length"
        v-model="selectKeys"
        :treeData="treeData"
        :defaultExpandAll="true"
        :tree-props="treeProps"
        @trigger-event="triggerEvent">
      </form-menu>
    </div>
    <div class="quality-meter" :key="key" v-loading="showList">
      <el-link
        v-if="tableInfo.module_uuid"
        class="link-module"
        type="primary"
        @click="enterIntoList">数据列表</el-link>
      <div class="quality-meter-tabs">
        <el-menu
          style="width: 100%; display: flex; justify-content: center"
          class="el-menu-demo"
          :default-active="defaultName"
          mode="horizontal"
          @select="handleClick"
        >
          <el-menu-item index="form"> 自定义表单 </el-menu-item>
          <el-menu-item index="flow"> 流程配置 </el-menu-item>
          <el-menu-item index="list"> 列表设计 </el-menu-item>
          <el-menu-item index="word"> 设置导出模板 </el-menu-item>
          <!--<el-menu-item index="table"> 自定义表格 </el-menu-item>-->
          <el-menu-item index="excel"> 设置Excel模板 </el-menu-item>
        </el-menu>
      </div>
      <div class="quality-meter-body">
        <custom-form v-if="activeName === 'form' && tableInfo.id" :tableInfo="tableInfo"></custom-form>
        <custom-config v-if="activeName === 'flow' && tableInfo.id" :tableInfo="tableInfo"></custom-config>
        <custom-list v-if="activeName === 'list' && tableInfo.id" :tableInfo="tableInfo"></custom-list>
        <custom-word v-if="activeName === 'word' && tableInfo.id" :tableInfo="tableInfo"></custom-word>
<!--        <custom-table v-if="activeName === 'table' && tableInfo.id" :tableInfo="tableInfo"></custom-table>-->
        <custom-excel v-if="activeName === 'excel' && tableInfo.id" :tableInfo="tableInfo"></custom-excel>
      </div>
    </div>


    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="30%">
      <div>
        <el-form ref="menuForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="菜单名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitMenu">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { dataInterface } from "@/apis/data/index";
import { Menu, MenuItem,Link } from "element-ui";
// import customTable from "./components/customTable.vue";
import customForm from "./components/customForm.vue";
import customConfig from "./components/customConfig.vue";
import customList from "./components/customList.vue";
import customWord from "./components/customWord";
import customExcel from "./components/customExcel";
import FormMenu from "@/components/common/FormMenu.vue";
import { dataInterface } from "@/apis/data/index";
export default {
  components: {
    // customTable,
    customForm,
    customConfig,
    FormMenu,
    customList,
    customWord,
    "el-link": Link,
    "el-menu": Menu,
    "el-menu-item": MenuItem,
    customExcel
  },
  data() {
    return {
      selfOtherFlag: this.$route.params.others,
      defaultName:'form',
      activeName: 'form',
      treeData: [],
      selectKeys: [],
      tableInfo: {},
      isStructure:false,// 是否是原始库数据
      showList:false,
      dialogTitle: '添加功能菜单',
      dialogVisible:false,
      parentMenu: null,
      actionFlag: 'add',
      treeProps:{
        label:'name',
        children:'children',
      },
      key: 0,
      ruleForm:{
        name:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
      }
    };
  },
  computed: {},
  watch: {
    '$route':{
      handler(to){
        this.selfOtherFlag = to.params.others
        this.getTreeData()
      },
      deep: true, // 深度监听
    },
  },
  created() {
    this.getTreeData()
  },
  mounted() {
    if(this.$route.query.menu_id) {
      this.selectKeys.push(+this.$route.query.menu_id)
      let params = {
        __method_name__: "dataInfo",
        object_uuid: "object626118627602e",
        transcode: 0,
        data_id: this.$route.query.menu_id,
      };
      this.showList = true
      // 请求数据详情  判断是否是原始库数据  原始库数据隐藏自定义表单 表格
      dataInterface(params).then((res) => {
        this.showList = false
        this.tableInfo = res.data.data
        this.activeName = 'form'
      })
    }
  },
  methods: {
    enterIntoList(){
      this.$router.push({
        path:'/form-data-list',
        query:{
          module_uuid: this.tableInfo.module_uuid
        }
      })
    },
    getTreeData(){
      let params = {}
      if(this.selfOtherFlag){
        params = {
          __method_name__: "dataList",
          object_uuid: "object626118627602e",
          view_uuid: "view62d67f6ec0d36",
          transcode: 0,
          func_flag: this.selfOtherFlag
        };
      } else {
        params = {
          __method_name__: "dataList",
          object_uuid: "object626118627602e",
          view_uuid: "view62611b53b28de",
          transcode: 0,
        };
      }
      dataInterface(params).then((res) => {
        if(res.data.code === 200){
          this.treeData = res.data.data
        }
      })
    },
    triggerEvent(eventName,flag,row){
      this[eventName](flag,row)
    },
    checkMenu(row){
      this.key = row.id
      this.activeName = 'form'
      this.tableInfo = row
      this.$router.push({
        query:{
          menu_id: row.id
        }
      })
    },
    addTreeMenu(){
      this.addMenu('add',null)
    },
    addMenu(flag,row){
      console.log(row)
      this.parentMenu = null
      this.dialogTitle = '添加功能菜单'
      this.ruleForm.name = ''
      this.actionFlag = flag
      if(flag === 'edit' && row){
        this.parentMenu = row
        this.ruleForm.name = row.name
        this.dialogTitle = '修改功能菜单'
      } else if(row){
        this.parentMenu = row
      }
      this.dialogVisible = true
    },
    submitMenu(){
      this.$refs.menuForm.validate((valid) => {
        if(valid){
          const params = {
            __method_name__: 'createData',
            object_uuid: 'object626118627602e',
            transcode: 0,
            name: this.ruleForm.name,
          }
          if(this.actionFlag === 'add'){
            params.parent_id = this.parentMenu?.id || 0
            if(this.parentMenu?.depth) {
              params.depth = this.parentMenu.depth + '_' + this.parentMenu.id
            } else {
              params.depth = 0
            }
            if(this.selfOtherFlag) params.func_flag = this.selfOtherFlag
          } else if(this.parentMenu) {
            params.__method_name__ = 'updateData'
            params.data_id = this.parentMenu.id
          }
          dataInterface(params).then((res) => {
            this.dialogVisible = false
            if(res.data.code === 200){
              this.getTreeData()
            }
          })
        }
      })
    },
    delMenu(row){
      this.$confirm('此操作删除此菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const params = {
          __method_name__: 'deleteData',
          object_uuid: 'object626118627602e',
          data_id: row.id,
          transcode: 0
        }
        dataInterface(params).then((res) => {
          if(res.data.code === 200){
            this.getTreeData()
          }
        })
      })
    },
    handleClick(data) {
      this.activeName = data;
    },
  },
};
</script>
<style lang="less" scoped>
.quality {
  width: 100%;
  height: calc(100vh - 130px);
  display: flex;
}
.quality-tree {
  width: 300px;
  border: 1px solid #f2f3f5;

  .title{
    padding: 10px 10px 0 15px;
  }
}
.quality-meter {
  position: relative;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .link-module{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 1000;
  }
  .quality-meter-tabs {
    display: flex;
    background-color: #fff;
    justify-content: center;
    position: relative;
    .button-class {
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
    }
  }
  .quality-meter-body {
    flex: 1;
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding-bottom: 10px;
    overflow-y: auto;
  }
}
</style>
